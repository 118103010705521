<template>
  <div style="margin: 10px; width: calc(100vw - 85px)">
    <el-form ref="user" v-loading="loading" :model="user" label-width="200px">
      <el-form-item :label="$t('profile.user_email')" prop="email">
        <span>{{ user.email }}</span>
      </el-form-item>
      <el-form-item :label="$t('profile.user_name')" prop="name">
        <el-input v-model="user.name" style="max-width: 200px" @input="dirty=true" />
      </el-form-item>
      <el-form-item :label="$t('profile.user_phone')">
        <el-input
          v-model="user.phone"
          style="max-width: 200px"
          type="tel"
          minlength="9"
          clearable
          prefix-icon="el-icon-phone"
          @input="dirty=true"
        />
      </el-form-item>
      <el-form-item>
        <span slot="label">{{ $t('Whatsapp notifications') }}<i class="fa fa-whatsapp" style="padding-left: 5px"></i></span>
        <el-switch v-model="user.attributes.whatsapp" style="max-width: 200px" @change="whatsappChanged" />
      </el-form-item>
      <el-form-item v-if="user.attributes" :label="$t('profile.user_timezone')">
        <el-select v-model="user.attributes.timezone" @change="dirty=true">
          <el-option v-for="timezone in timezones" :key="timezone.value" :label="timezone.text" :value="timezone.value" />
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('profile.user_language')">
        <el-select v-model="user.attributes.lang" @change="dirty=true">
          <el-option v-for="lang in languages" :key="lang.value" :label="lang.text" :value="lang.value" />
        </el-select>
      </el-form-item>
      <el-link style="padding-bottom: 20px" :href="forgotPass" type="primary" target="_blank">{{ $t('profile.user_password') }}</el-link>
    </el-form>
    <el-button :loading="loading" type="primary" :disabled="!dirty" @click="submit">{{ $t('profile.user_update_button') }}</el-button>
  </div></template>

<script>
import * as lnglat from '../../../utils/lnglat'
import { mapGetters } from 'vuex'
import { languages, setLanguage } from '@/lang'
import { timezones } from '@/utils/consts'
import { traccar } from '@/api/traccar-api'
import { serverBus } from '@/main'
import { forgotPassword } from '@/api'
import { schedulerIsActive } from '@/utils/modules'
import store from '@/store'
import { pinmeapi } from '@/api/pinme'

export default {
  name: 'Settings',
  data() {
    return {
      radioValue: 'road',
      routeColor: 0,
      dirty: false,
      loading: false,
      languages: languages,
      timezones: timezones,
      passwordType: 'password',
      rules: {
        name: [
          { required: true, message: this.$t('profile.user_name_required'), trigger: 'blur' }
        ]
      }
    }
  },
  computed: {
    ...mapGetters(['vehicles3dEnabled', 'user']),
    forgotPass() {
      return forgotPassword()
    },
    labelColor: {
      get() { return this.$store.getters.labelColor },
      set(value) {
        this.changeSetting('labelColor', value)
      }
    },
    labelTextSize: {
      get() { return this.$store.getters.labelTextSize },
      set(value) {
        this.changeSetting('labelTextSize', parseInt(value))
      }
    },
    labelPOITextSize: {
      get() { return this.$store.getters.labelPOITextSize },
      set(value) {
        this.$store.commit('map/SET_LABEL_POI_TEXT_SIZE', parseInt(value))
      }
    },
    showFullDate: {
      get() { return this.$store.state.settings.showFullDate },
      set(value) {
        this.$store.commit('settings/SET_SHOW_FULLDATE', value)
      }
    },
    showLabels: {
      get() { return this.$store.state.settings.showLabels },
      set(value) {
        this.$store.dispatch('settings/setShowLabels', value)
      }
    },
    top() {
      if (('standalone' in window.navigator) && window.navigator.standalone) {
        return 'padding-top:100px;'
      }
      return 'padding-top:15px'
    },
    isMobile() { return lnglat.isMobile() },
    manageSchedules() {
      return schedulerIsActive(store.getters.user)
    },
    matchRoutes: {
      get() { return this.$store.state.settings.matchRoutes },
      set(value) {
        this.$store.dispatch('settings/changeSetting', {
          key: 'matchRoutes',
          value: value
        })
      }
    },
    viewSpeedAlerts: {
      get() { return this.$store.state.getters.viewSpeedAlerts },
      set(value) {
        this.$store.dispatch('settings/changeSetting', {
          key: 'viewSpeedAlerts',
          value: value
        })
      }
    },
    icons3d: {
      get() { return this.vehicles3dEnabled },
      set(value) {
        this.$store.dispatch('settings/changeSetting', {
          key: 'vehicles3d',
          value: value
        })
      }
    }
  },
  methods: {
    tabClicked(e) {
      serverBus.$emit('driversClicked', e)
    },
    changeSetting(key, value) {
      this.$store.dispatch('settings/changeSetting', { key, value })
    },
    showPwd() {
      if (this.passwordType === 'password') {
        this.passwordType = ''
      } else {
        this.passwordType = 'password'
      }
      this.$nextTick(() => {
        this.$refs.password.focus()
      })
    },
    submit() {
      this.$refs.user.validate(valid => {
        if (valid) {
          this.loading = true
          traccar.updateUser(this.user.id, this.user
          ).then(({ data }) => {
            this.dirty = false
            setLanguage(data.attributes.lang)
            this.$message({
              message: this.$t('profile.user_updated'),
              type: 'success',
              duration: 5 * 1000
            })
          }).catch((e) => {
            this.$message({
              message: e,
              type: 'error',
              duration: 5 * 1000
            })
          }).finally(() => { this.loading = false })
        }
      })
    },
    async whatsappChanged(value) {
      this.dirty = !value
      if (value) {
        try {
          await this.$confirm(
            this.$t('Al activar esta opción recibirá mensajes de alarmas en su whatsapp, si deséa cancelar vuelva a esta pantalla y desactive la opcion. Desea continuar?'),
            this.$t('Aviso'), {
              confirmButtonText: this.$t('Continuar'),
              cancelButtonText: this.$t('Cancelar'),
              type: 'warning'
            })
          this.loading = true
          await pinmeapi.sendWhatsApp(this.user, 'optin')
          await traccar.updateUser(this.user.id, this.user)
          this.$message({ type: 'success', message: this.$t('Enviámos un mensaje de confirmación a su móvil.') })
        } catch (e) {
          this.user.attributes.whatsapp = false
          if (e.message) { this.$message({ type: 'error', message: e }) }
        }
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'src/styles/element-variables';
.el-avatar {
  color:$--color-text-regular;
  opacity: 0.95;
  font-weight: bold;
}
a:link {
  text-decoration: none;
}
.right-menu {
  position: absolute;
  right: 15px;
  top:15px;
  background: rgba(0, 0, 0, 0);
  z-index: 99;
}
$dark_gray:#889aa4;
.show-pwd {
  position: absolute;
  right: 10px;
  top: 1px;
  font-size: 16px;
  color: $dark_gray;
  cursor: pointer;
  user-select: none;
}
.app-container {
    padding-left: 10px;
  }
</style>

